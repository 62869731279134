import Vue from 'vue'
import Vuex from 'vuex'
import { loginUser } from '@/services/authService'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        correlationId: null,
        token: null,
        user: {},
        authStatus: false
    },
    getters: {
        getCorrelationId: state => state.correlationId,
        isAuthenticated(state){
          return !!state.token;
        },
        authStatus: state => state.authStatus,
        user: state => state.user
    },
    mutations: {
        setCorrelationId(state, corrId) {
            state.correlationId = corrId
        },
        SET_TOKEN (state, token) {
            state.token = token
        },
        LOGIN_USER (state, user) {
            state.authStatus = true
            state.user = { ...user }
        },
        LOGOUT_USER (state) {
            state.authStatus = ''
            state.token = '' && localStorage.removeItem('apollo-token')
        }
    },
    actions: {
        async login ({ commit, dispatch }, authDetails) {
            try {
                await loginUser(commit, authDetails.email, authDetails.password)
                //const { data } = await apolloClient.mutate({ mutation: LOGIN_USER, variables: { ...authDetails } })
                //const token = data;
                //commit('SET_TOKEN', token)
                //localStorage.setItem('apollo-token', token)
                dispatch('setUser')
            } catch (e) {
                console.log(e)
            }
        }
    }

})
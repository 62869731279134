<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
      <tr>
        <th class="text-left">
          Author
        </th>
        <th class="text-left">
          Beschreibung
        </th>
        <th class="text-left">
          Sprache
        </th>
        <th class="text-left">
          Seite
        </th>
      </tr>
      </thead>
      <tbody>
      <tr
          v-for="item in article.articleInfo.bookReferences"
          :key="item.symptom"
      >
        <td>{{ item.autor }}</td>
        <td>{{ item.description }}</td>
        <td>{{ item.language }}</td>
        <td>{{ item.page }}</td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: "ArticleReferences",
  props: ['article']
}
</script>

<style scoped>

</style>
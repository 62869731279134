<template>
  <div class="detailForm">
    <v-btn
        class="ma-2"
        color="#ffb511"
        dark
        v-on:click="save"
    >
      Speichern
      <v-icon
          dark
          right
      >
        mdi-content-save
      </v-icon>
    </v-btn>
    <v-btn
        class="ma-2"
        color="#ffb511"
        dark
        v-on:click="cancel"
    >
      Abbrechen
      <v-icon
          dark
          right
      >
        mdi-close-thick
      </v-icon>
    </v-btn>
    <v-btn
        class="ma-2"
        color="#ffb511"
        dark
        v-on:click="remove"
    >
      Löschen
      <v-icon
          dark
          right
      >
        mdi-delete
      </v-icon>
    </v-btn>
    <div v-if="this.article != null" class="row">
      <div class="col-md-3">
        <LabelEdit title="PinYin" :content.sync="article.pinYin" />
      </div>
      <div class="col-md-3">
        <LabelEdit title="PharmaName" :content.sync="article.pharmaName" />
      </div>
      <div class="col-md-3">
        <LabelEdit title="Chinesischer Name" :content.sync="article.chinese" />
      </div>
      <div class="col-md-3">
        <LabelEdit title="ArtikelNummer" :content.sync="article.articleNumber" />
      </div>
    </div>
    <div class="row"></div>
      <div class="col-md-12">
        &nbsp;
      </div>
    <v-divider></v-divider>
    <v-tabs>
      <v-tab href="#overview">Übersicht</v-tab>
      <v-tab-item value="overview">
        <v-card flat>
          <EditArticleOverview v-if="this.article != null" v-bind:article="this.article" />
        </v-card>
      </v-tab-item>

      <v-tab href="#modification">Modifikation</v-tab>
      <v-tab-item value="modification">
        <EditModification v-bind:modifications="this.article.modifications" v-if="this.article != null" />
      </v-tab-item>

      <v-tab href="#reference">Informations Referenz</v-tab>
      <v-tab-item value="reference">
        <EditArticleReferences v-bind:references="this.article.articleInfo.bookReferences" v-if="this.article != null"/>
      </v-tab-item>

      <v-tab href="#images">Bilder</v-tab>
      <v-tab-item value="images">
        <ArticleImages/>
      </v-tab-item>

    </v-tabs>
  </div>
</template>

<script>
import EditArticleOverview from "@/components/EditArticleOverview";
import ArticleImages from "@/components/ArticleImages";
import {getArticlesByArticleNumber, newArticle, updateArticle} from "@/services/articleService";
import LabelEdit from "@/components/LabelEdit";
import EditModification from "@/components/EditModification";
import EditArticleReferences from "@/components/EditArticleReferences";
import router from "@/router/router";

export default {
  name: "EditArticle",
  components: {EditArticleReferences, EditModification, LabelEdit, ArticleImages, EditArticleOverview},
  data: function() {
    return {
      article: null
    }
  },
  methods: {
    async load() {
      if(this.$route.params.articleNumber) {
        var data = await getArticlesByArticleNumber(this.$route.params.articleNumber);
        this.article = data.data.articles[0];
      }
      else {
        var newdata = await newArticle();
        this.article = newdata.data.newArticle;
      }
    },
    save() {
      updateArticle(this.article);
    },
    cancel() {
      router.push({path: '/?admin=true'});
    },
    remove() {
      alert('noch nicht da');
    }
  },
  created() {
    this.load();
  }
}
</script>

<style scoped>

</style>
<template>
  <v-carousel>
    <v-carousel-item
        v-for="(item,i) in article.images"
        :key="i"
        :src="item.src"
        reverse-transition="fade-transition"
        transition="fade-transition"
    ></v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
name: "ArticleImages",
  data: function() {
    return {
      article: {
        articleNumber: "0013",
        pharmaName: "Linking C",
        pinYin: "Yi Guan Jian",
        chinese: "一 贯 煎",
        category: "Tonisieren",
        subCategory: "Yin tonisieren",
        indicationTCM: "Leber- und/oder Nieren-Yin-Mangel mit Leber-Qi-Stagnation und disharmonischer Beziehung zwischen Leber und Magen: Schmerzen in Brust, Epigastrium und Hypochondrium, Sodbrennen, Mund- und Halstrockenheit mit bitterem Mundgeschmack",
        incicationMedicin: "Leberzirrhose, chronische Gastritis, Ulcus pepticum, prämenstruelles Syndrom (PMS), Hepatitis, Beckenerkrankungen, Hyperthyreose, Restless legs-Syndrom",
        contraIndication: "Diese Rezeptur ist bei Feuchtigkeits-Schleim-Retention mit weissem, schmierigem oder klebrigem Zungenbelag und tiefem und saitenförmigem Puls kontraindiziert.",
        tongueDiagnosticZB: "trocken",
        tongueDiagnosticZK: "rot",
        recommendedDailyDose: "18g",
        remarkDailyDose: "maximal!",
        pulseFeeling: "fadenförmig und schwach oder leer und saitenförmig",
        images: [
          {
            src: "5025.jpg"
          },
          {
            src: "5050.jpg"
          },
          {
            src: "5056.jpg"
          }
        ],
        modifications: [
          {
            "symptom": "Obstipation",
            "addItem": "Gua Lou Ren"
          },
          {
            "symptom": "Schlaflosigkeit",
            "addItem": "Souan Zao Ren"
          },
          {
            "symptom": "Schweiss aufgrund von Mangel-Hitze",
            "addItem": "Shi Hu"
          }
        ]}
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="detailForm">
    <v-btn
        class="ma-2 black--text"
        style="margin-left: 0px!important;"
        color="#FCD31D"
        dark
        v-on:click="backToList"
    >
      Zurück zur Suche
      <v-icon
          dark
          right
      >
        mdi-magnify
      </v-icon>
    </v-btn>
    <v-btn
        v-if="$store.getters.getCorrelationId"
        class="ma-1"
        color="#FCD31D"
        dark
        v-on:click="addArticleToCW()"
    >
      Zu Compleweb hinzufügen
      <v-icon
          dark
      >
        mdi-send
      </v-icon>
    </v-btn>
    <div v-if="this.article != null" class="row">
      <div class="col-md-3">
      </div>
      <div class="col-md-3">
      </div>
      <div class="col-md-3">
      </div>
      <div class="col-md-3" v-if="this.article.dosageInfo != null">
        <LabelDescription v-if="this.article.dosageInfo.benskyDosageInfo != null" title="RD-Tagesdosierung(Bensky)" :content="article.dosageInfo.benskyDosageInfo" />
      </div>
    </div>
    <div v-if="this.article != null" class="row">
      <div class="col-md-3">
        <LabelDescription title="PinYin" :content="article.pinYin" />
      </div>
      <div class="col-md-3">
        <LabelDescription title="PharmaName" :content="article.pharmaName" />
      </div>
      <div class="col-md-3">
        <LabelDescription title="Chinesischer Name" :content="article.chinese" />
      </div>
      <div class="col-md-3">
        <LabelListDescription title="ArtikelNummern" :content="article.articleInfo.articles" />
      </div>
    </div>
    <div class="row"></div>
      <div class="col-md-12">
        &nbsp;
      </div>
    <v-divider></v-divider>
    <v-tabs v-if="article != null" color="#987C00">
      <v-tab href="#overview">Übersicht</v-tab>
      <v-tab-item value="overview">
        <v-card flat>
          <ArticleOverview v-if="this.article != null" v-bind:article="this.article" />
        </v-card>
      </v-tab-item>

      <v-tab v-if="article.ingredients.length > 0" href="#ingredients">Zusammensetzung</v-tab>
      <v-tab-item value="ingredients">
        <ArticleIngredients v-if="this.article != null" v-bind:article="this.article"/>
      </v-tab-item>

      <v-tab v-if="article.modifications.length > 0" href="#modification">Modifikation</v-tab>
      <v-tab-item value="modification">
        <ArticleModifications v-if="this.article != null" v-bind:article="this.article"/>
      </v-tab-item>

      <v-tab v-if="article.partOf.length > 0" href="#partOf">Artikel ist vorhanden in</v-tab>
      <v-tab-item value="partOf">
        <ArticleIsPartOf v-if="this.article != null" v-bind:article="this.article"/>
      </v-tab-item>

      <v-tab v-if="article.articleInfo.bookReferences.length > 0" href="#reference">Informations Referenz</v-tab>
      <v-tab-item value="reference">
        <ArticleReferences v-if="this.article != null" v-bind:article="this.article"/>
      </v-tab-item>
    </v-tabs>
    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
            color="#FCD31D"
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Schliessen
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import ArticleOverview from "@/components/ArticleOverview";
import LabelDescription from "@/components/LabelDescription";
import LabelListDescription from "@/components/LabelListDescription";
import ArticleModifications from "@/components/ArticleModifications";
import ArticleReferences from "@/components/ArticleReferences";
import ArticleIsPartOf from "@/components/ArticleIsPartOf";
import {getArticlesByArticleNumber} from "@/services/articleService";
import router from "@/router/router";
import ArticleIngredients from "@/components/ArticleIngredients";
import {addArticleToCompleweb} from "@/services/complewebService";
export default {
  name: "ArticleDetails",
  components: {
    ArticleIngredients,
    ArticleReferences,
    ArticleModifications,
    LabelDescription,
    LabelListDescription,
    ArticleOverview,
    ArticleIsPartOf},
  data: function() {
    return {
      article: null,
      snackbar: false,
      text: '',
      timeout: 3000,
    }
  },
  methods: {
    async addArticleToCW(){
      await addArticleToCompleweb(this.$store.getters.getCorrelationId, this.$route.params.articleNumber);
      this.text = "Artikel " + this.$route.params.articleNumber + " wurde an Compleweb übertragen";
      this.snackbar = true;
    },
    backToList() {
      router.push({path: '/'})
    },
    async load() {
      var data = await getArticlesByArticleNumber(this.$route.params.articleNumber);
      this.article = data.data.articles[0];
    }
  },
  created() {
    this.load();
  }
}
</script>

<style scoped>

</style>

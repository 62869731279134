<template>
  <img height="24" src="@/appimages/zunge-zk.svg">
</template>

<script>
export default {
name: "TongueZK"
}
</script>

<style scoped>

</style>
<template>
  <v-row>
    <v-col md="6" :key="updateKey">
      <v-simple-table >
        <template v-slot:default>
          <thead>
          <tr>
            <th></th>
            <th class="text-left">
              Artikelnummer
            </th>
            <th class="text-left">
              Pharma
            </th>
            <th class="text-left">
              PinYin
            </th>
            <th class="text-left">
              Chinesisch
            </th>
            <th class="text-left">
              Anteil %
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="item in article.ingredients"
              :key="item.articleNumber"
              :class="selectedRows.indexOf(item.pinYin) == -1?'':'cyan'"
          >
            <td ><v-btn v-if="!admin" @click="showDetails(item.articleNumber)">
              <v-icon
                  dark
              >
                mdi-eye-plus
              </v-icon>
            </v-btn></td>
            <td>{{ item.articleNumber }}</td>
            <td>{{ item.pharmaName }}</td>
            <td>{{ item.pinYin }}</td>
            <td>{{ item.chinese }}</td>
            <td>{{ item.share }}</td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
    <v-col md="6">
      <PieChart v-if="article != undefined" :article="article" @on-receive="selectItem"/>
    </v-col>
  </v-row>


</template>

<script>
import router from "@/router/router";
import PieChart from "@/components/PieChart";

export default {
  name: "ArticleIngredients",
  components: {
    PieChart
  },
  props: ['article'],
  methods: {
    showDetails(value) {
      router.push({path: value})
    },
    selectItem(data){
      if (this.selectedRows.includes(data.pinYin)) {
        this.selectedRows = this.selectedRows.filter(
            selectedKeyID => selectedKeyID !== data.pinYin
        );
      } else {
        this.selectedRows.push(data.pinYin);
      }
      this.updateKey += 1;
    }
  },
  data ()
  {
    return {
      updateKey: 0,
      selectedRows: [],
      headers: [
        {

        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div>Jumpoff</div>
</template>

<script>
import router from "@/router/router";

export default {
name: "Jumpoff",
  created: function() {
    this.$store.commit('setCorrelationId', this.$route.params.correlationId);

    var article = this.$route.params.articleNumber;

    if(article == null) {
      router.push({path: '/'})
    } else {
      router.push({path: '/detail/' + article})
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="detailForm">
  <v-container fluid>
    <v-layout row wrap style="height: 60px">
      <v-flex md8>
        <v-text-field
            label="Allgemeiner Suchbegriff"
            hide-details
            v-model="general"
            @input="loadArticles"
        ></v-text-field>
      </v-flex>
      <v-flex md4>
        <v-combobox
            v-model="selectedCategories"
            :items="categories"
            label="Kategorie"
            multiple
            @input="loadArticles"
        ></v-combobox>
      </v-flex>
    </v-layout>
    <v-layout row wrap style="height: 60px">
      <v-flex md4>
        <v-text-field
            label="Pharmaname"
            hide-details
            v-model="pharmaName"
            @input="loadArticles"
        ></v-text-field>
      </v-flex>
      <v-flex md4>
        <v-text-field
            label="Indikation Schulmedizin"
            hide-details
            v-model="indicationSchool"
            @input="loadArticles"
        ></v-text-field>
      </v-flex>
      <v-flex md4>
        <v-text-field
            label="Puls"
            hide-details
            v-model="selectedPulse"
            @input="loadArticles"
        ></v-text-field>
<!--        <v-combobox
            v-model="selectedPuls"
            :items="pulses"
            label="Puls"
            multiple
            @input="loadArticles"
        ></v-combobox>-->
      </v-flex>
    </v-layout>
    <v-layout row wrap style="height: 60px">
      <v-flex md4>
        <v-text-field
            label="PinYin"
            hide-details
            v-model="pinYin"
            @input="loadArticles"
        ></v-text-field>
      </v-flex>
      <v-flex md4>
        <v-text-field
            label="Indikation TCM"
            hide-details
            v-model="indicationTCM"
            @input="loadArticles"
        ></v-text-field>
      </v-flex>
      <v-flex md4>
        <v-text-field
            v-model="selectedTongue"
            hide-details
            label="Zunge"
            @input="loadArticles"
        ></v-text-field>
      </v-flex>
    </v-layout>
    <v-layout row wrap style="height: 60px">
      <v-flex md4>
        <v-combobox
            v-model="selectedTemperatures"
            :items="temperatures"
            label="Temperatur"
            multiple
            @input="loadArticles"
        ></v-combobox>
      </v-flex>
      <v-flex md4>
        <v-combobox
            v-model="selectedSmells"
            :items="smells"
            label="Geschmack"
            multiple
            @input="loadArticles"
        ></v-combobox>
      </v-flex>
      <v-flex md4>
        <v-combobox
            v-model="selectedMeridians"
            :items="meridians"
            label="Leitbahnen"
            multiple
            @input="loadArticles"
        ></v-combobox>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex 12>
        <br>
        <v-btn
            class="ma-2 black--text"
            color="#FCD31D"
            dark
            v-on:click="loadArticles"
        >
          Suchen
          <v-icon
              dark
              right
          >
            mdi-magnify
          </v-icon>
        </v-btn>
        <v-btn
            class="ma-2 black--text"
            color="#FCD31D"
            style="margin-left: 100px;"
            dark
            v-on:click="clearFilter"
        >
          Filter löschen
          <v-icon
              dark
              right
          >
            mdi-filter-off
          </v-icon>
        </v-btn>
        <v-btn v-if="admin"
               class="mx-2"
               fab
               dark
               small
               color="#FCD31D"
               v-on:click="newArticle"
        >
          <v-icon dark>
            mdi-plus
          </v-icon>
        </v-btn>
        <div>Alle Angaben ohne Gewähr</div>
        <div style="margin-top: 12px">
          <span style="margin-left: 8px;padding-top: 52px">Gefundene Artikel: {{articlesCount}}</span>
        </div>
      </v-flex>

      <v-flex md12>
        <v-data-table
            :options="{ showDetails }"
            :headers="headers"
            :items="articles"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            @page-count="pageCount = $event"
            v-if="articles">
          <template v-slot:item.details="{item}">
            <v-btn v-if="!admin" @click="showDetails(item.articleNumber)">
              <v-icon
                  dark
              >
                mdi-eye-plus
              </v-icon>
            </v-btn>
            <v-btn v-if="admin" @click="edit(item.articleNumber)" >
              <v-icon
                  dark
              >
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
          <template v-slot:item.article="{item}">
            <span :inner-html.prop="item.pharmaName | highlight(general)"></span><br>{{ item.pinYin }}<br>{{ item.chinese }}
          </template>
          <template v-slot:item.indicationSchool="{item}">
            <span :inner-html.prop="item.articleInfo.indicationInformations.schoolMedicine | highlight(general)"></span>
          </template>
          <template v-slot:item.indicationTCM="{item}">
            <span :inner-html.prop="item.articleInfo.indicationInformations.tcm | highlight(general)"></span>
          </template>
          <template v-slot:item.contraIndication="{item}">
            {{item.articleInfo.indicationInformations.contraIndication}}
          </template>
          <template v-slot:item.category="{item}">
            <span :inner-html.prop="item.articleInfo.indicationInformations.category.name | highlight(general)" v-if="item.articleInfo.indicationInformations.category"></span>
          </template>
          <template v-slot:item.pulseTongue="{item}">
            <span v-if="item.articleInfo.indicationInformations.pulseSensing != null"><v-icon>mdi-heart-pulse</v-icon><span :inner-html.prop="item.articleInfo.indicationInformations.pulseSensing | highlight(general)"></span><br></span>
            <span v-if="item.articleInfo.indicationInformations.tongueDiagnosticsZB != null"><TongueZB/><span :inner-html.prop="item.articleInfo.indicationInformations.tongueDiagnosticsZB | highlight(general)"></span><br></span>
            <span v-if="item.articleInfo.indicationInformations.tongueDiagnosticsZK != null"><TongueZK/><span :inner-html.prop="item.articleInfo.indicationInformations.tongueDiagnosticsZK | highlight(general)"></span><br></span>
          </template>
          <template v-slot:item.jumpoff="{item}">
              <v-btn
                  class="ma-1"
                  color="#FCD31D"
                  dark
                  v-on:click="addArticleToCW(item.articleNumber)"
                  v-if="$store.getters.getCorrelationId"
              >
                <v-icon
                    dark
                >
                  mdi-send
                </v-icon>
              </v-btn>
          </template>
<!--        <template v-slot:default>
          <thead>
          <tr>
            <th></th>
            <th class="text-left" width="200px">
              Artikel
            </th>
            <th class="text-left" width="20%">
              Indikation Schulmedizin
            </th>
            <th class="text-left" width="20%">
              Indikation TCM
            </th>
            <th class="text-left" width="20%">
              Vorsichtsmassnahmen / Kontraindikationen
            </th>
            <th class="text-left">
              Kategorie
            </th>
            <th class="text-left">
              Puls/Zunge
            </th>
            <th class="text-left" v-if="$store.getters.getCorrelationId">
              CW
            </th>
          </tr>
          </thead>
          <tbody style="vertical-align: top">
          <tr
              v-for="item in articles"
              :key="item.name"
              style="margin-top: 15px;"
          >
            <td class="overviewrow">
              <v-btn v-if="!admin" @click="showDetails(item.articleNumber)">
                <v-icon
                dark
                >
                  mdi-eye-plus
                </v-icon>
              </v-btn>
              <v-btn v-if="admin" @click="edit(item.articleNumber)" >
                <v-icon
                    dark
                >
                  mdi-pencil
                </v-icon>
              </v-btn>
            </td>
            <td class="overviewrow"><span :inner-html.prop="item.pharmaName | highlight(general)"></span><br>{{ item.pinYin }}<br>{{ item.chinese }}</td>
            <td class="overviewrow" v-if="item.articleInfo.indicationInformations !== null"><span :inner-html.prop="item.articleInfo.indicationInformations.schoolMedicine | highlight(general)"></span></td><td v-else></td>
            <td class="overviewrow" v-if="item.articleInfo.indicationInformations !== null"><span :inner-html.prop="item.articleInfo.indicationInformations.tcm | highlight(general)"></span></td><td v-else></td>
            <td class="overviewrow" v-if="item.articleInfo.indicationInformations !== null">{{ item.articleInfo.indicationInformations.contraIndication }}</td><td v-else></td>
            <td class="overviewrow" v-if="item.articleInfo.indicationInformations !== null"><span v-if="item.articleInfo.indicationInformations.category != null"><span :inner-html.prop="item.articleInfo.indicationInformations.category.name | highlight(general)"></span><br></span><span v-if="item.articleInfo.indicationInformations.subCategory != null">{{ item.articleInfo.indicationInformations.subCategory.name }}</span></td><td v-else></td>
            <td class="overviewrow" v-if="item.articleInfo.indicationInformations !== null">
              <span v-if="item.articleInfo.indicationInformations.pulseSensing != null"><v-icon>mdi-heart-pulse</v-icon><span :inner-html.prop="item.articleInfo.indicationInformations.pulseSensing | highlight(general)"></span><br></span>
              <span v-if="item.articleInfo.indicationInformations.tongueDiagnosticsZB != null"><TongueZB/><span :inner-html.prop="item.articleInfo.indicationInformations.tongueDiagnosticsZB | highlight(general)"></span><br></span>
              <span v-if="item.articleInfo.indicationInformations.tongueDiagnosticsZK != null"><TongueZK/><span :inner-html.prop="item.articleInfo.indicationInformations.tongueDiagnosticsZK | highlight(general)"></span><br></span></td><td v-else></td>
            <td v-if="$store.getters.getCorrelationId">
              <v-btn
                  class="ma-1"
                  color="#FCD31D"
                  dark
                  v-on:click="addArticleToCW(item.articleNumber)"
              >
                <v-icon
                    dark
                >
                  mdi-send
                </v-icon>
              </v-btn>
            </td>
          </tr>
          </tbody>
        </template>-->
      </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
              v-model="page"
              :length="pageCount"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
            color="#FCD31D"
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Schliessen
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import {getArticles, getArticlesGeneral} from "@/services/articleService";
import {getEffectCategories} from "@/services/categoryService";
import {addArticleToCompleweb} from "@/services/complewebService";
import router from "@/router/router";
import TongueZB from "@/appimages/TongueZB";
import TongueZK from "@/appimages/TongueZK";
import {getTemperatures} from "@/services/temperatureService";
import {getSmells} from "@/services/smellsService";
import {getMeridians} from "@/services/meridiansService";

export default {
name: "AllArticles",
  components: {
    TongueZB,
    TongueZK,
  },
  data () {
    return {
      headers: [
        {text: '', value: 'details'},
        {text: 'Artikel', value: 'article'},
        {text : 'Indikation Schulmedizin', value: 'indicationSchool'},
        {text : 'Indikation TCM', value: 'indicationTCM'},
        {text : 'Vorsichtamassnahmen / Kontraindikationen', value: 'contraIndication'},
        {text : 'Kategorie', value: 'category'},
        {text : 'Puls/Zunge', value: 'pulseTongue'},
        {text : '', value: 'jumpoff'}
      ],
      admin: false,
      loading: false,
      articles: null,
      articleNumber: "",
      pharmaName: "",
      pinYin: "",
      indicationSchool: "",
      indicationTCM: "",
      selectedCategories: null,
      selectedTemperatures: null,
      selectedSmells: null,
      selectedMeridians: null,
      selectedTongue: "",
      selectedPulse: "",
      pulses: [],
      general: "",
      categories: [],
      temperatures: [],
      smells: [],
      meridians: [],
      articlesCount: 0,
      snackbar: false,
      text: '',
      timeout: 3000,
      pageCount: 0,
      page: 1,
      itemsPerPage: 50,
    }
  },
  methods: {
    async addArticleToCW(articleNumber){
      await addArticleToCompleweb(this.$store.getters.getCorrelationId, articleNumber);
      this.text = "Artikel " + articleNumber + " wurde an Compleweb übertragen";
      this.snackbar = true;
    },
    async clearFilter() {
      this.general = "";
      this.pharmaName = "";
      this.pinYin = "";
      this.indicationTCM = "";
      this.indicationSchool = "";
      this.selectedPulse = "";
      this.selectedTongue = "";
      this.selectedCategories = null;
      this.loadArticles();
    },
    async loadArticles() {
      var data = null;
      if(this.general.length > 0) {
        data = await getArticlesGeneral(this.general, this.selectedCategories, this.selectedTemperatures, this.selectedSmells, this.selectedMeridians);
      } else {
        data = await getArticles(
            this.articleNumber,
            this.pharmaName,
            this.pinYin,
            this.indicationSchool,
            this.indicationTCM,
            this.selectedTongue,
            this.selectedPulse,
            this.selectedCategories,
            this.selectedTemperatures,
            this.selectedSmells,
            this.selectedMeridians
        );
      }
      this.articles = data.data.articles;
      this.articlesCount = this.articles.length;
    },
    async loadCategories() {
      var data = await getEffectCategories();

      this.categories = [];
      data.data.effectCategories.forEach(item => {
        this.categories.push(item.name)
      });
    },
    async loadTemperatures() {
      var data = await getTemperatures();

      this.temperatures = [];
      data.data.temperatures.forEach(item => {
        this.temperatures.push(item)
      });
    },
    async loadSmells() {
      var data = await getSmells();

      this.smells = [];
      data.data.smells.forEach(item => {
        this.smells.push(item)
      });
    },
    async loadMeridians() {
      var data = await getMeridians();

      this.meridians = [];
      data.data.meridians.forEach(item => {
        this.meridians.push(item)
      });
    },
    showDetails(value) {
      router.push({path: 'detail/' + value})
    },
    newArticle() {
      router.push({path: 'edit'})
    },
    edit(value) {
      router.push({path: 'edit/' + value})
    }
  },
  props: (route) => ({ admin: (String(route.query.admin).toLowerCase() === 'true') }),
  created() {
    this.admin = (String(this.$route.query.admin).toLowerCase() === 'true');
    this.loadArticles();
    this.loadCategories();
    this.loadTemperatures();
    this.loadSmells();
    this.loadMeridians();
  },
  filters: {
    highlight(value, query){
      if(query && query != '') {
        return value.replace(new RegExp(query, "ig"), '<span style="background-color: #FCD31D">' + query + '</span>')
      }
      return value;
    }
  },
}
</script>

<style scoped>

</style>
import { Pie } from "vue-chartjs";

export default {
    extends: Pie,
    props: {
        article: {
            type: Object,
            default: null
        }
    },
    mounted() {
        this.gradient = this.$refs.canvas
            .getContext("2d")
            .createLinearGradient(0, 0, 0, 450);
        this.gradient2 = this.$refs.canvas
            .getContext("2d")
            .createLinearGradient(0, 0, 0, 450);

        this.gradient.addColorStop(0, "rgba(255, 0,0, 0.5)");
        this.gradient.addColorStop(0.5, "rgba(255, 0, 0, 0.25)");
        this.gradient.addColorStop(1, "rgba(255, 0, 0, 0)");

        this.gradient2.addColorStop(0, "rgba(0, 231, 255, 0.9)");
        this.gradient2.addColorStop(0.5, "rgba(0, 231, 255, 0.25)");
        this.gradient2.addColorStop(1, "rgba(0, 231, 255, 0)");

        this.labels = [];
        this.values = [];

        this.article.ingredients.forEach(p => {
            this.labels.push(p.pinYin);
            this.values.push(p.share);
        });


        this.renderChart(
            {

                labels: this.labels,
                datasets: [
                    {
                        backgroundColor: ["#844c38", "#c2422e", "#d6653f", "#f09707", "#fcc700", "#d6911a",
                        "#dbc743","#e0dd5f", "#fff7bc", "#c2b91b", "#bc9b2e", "#988300", "#806900", "#6d6514",
                        "#788b19", "#7db96d", "#166436"],
                        data: this.values
                    }
                ]
            },
            { responsive: true, maintainAspectRatio: false, onClick:this.handleClick }
        );
    },
    methods: {
        handleClick(point, event) {
            const item = event[0]
            this.$emit('on-receive', {
                pinYin: this.article.ingredients[item._index].pinYin
            })
        }
    }
};
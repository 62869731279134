<template>
  <div class="labelDescription">
    <div class="smallTitle">{{ title }}</div>
    <div v-if="content">{{content}}</div><div v-else>-</div>
  </div>
</template>

<script>
export default {
  name: "LabelDescription",
  props: [
      'title',
      'content'
  ]
}
</script>

<style scoped>

</style>
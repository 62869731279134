<template>
  <img height="24" src="@/appimages/zunge-zb.svg">
</template>

<script>
export default {
name: "TongueZB"
}
</script>

<style scoped>

</style>
import {apolloClient} from "@/vue-apollo";
import ADD_ARTICLE_TO_COMPLEWEB from "@/graphql/AddArticle.gql";

export const addArticleToCompleweb = async (correlationId, articleNumber) => {
    var input = {
        "correlationId": correlationId,
        "articleNumber": articleNumber
    }
    return await apolloClient.mutate({
        mutation: ADD_ARTICLE_TO_COMPLEWEB,
        variables: { input: input }
    });
};
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import TongueZB from '../appimages/zunge-zb.svg';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdiSvg', // default - only for display purposes
        custom: {
            tongueZb:TongueZB
        }
    }
});

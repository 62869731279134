import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import { createProvider } from './vue-apollo'
import router from "./router/router";
import "@mdi/font/css/materialdesignicons.min.css";
import './assets/site.css'
import store from './store'
// import Lightbox from 'vue-easy-lightbox'

Vue.config.productionTip = false

Vue.use(Vuex)
// Vue.use(Lightbox)

new Vue({
  vuetify,
  router,
  apolloProvider: createProvider(),
  render: h => h(App),
  store
}).$mount('#app')

<template>
  <div v-if="article != null" class="row">
    <div class="col-md-6">
      <LabelDescription title="Kategorie" :content="article.articleInfo.indicationInformations.category.name" v-if="article.articleInfo.indicationInformations.category != null"/>
      <LabelDescription title="Sub Kategorie" :content="article.articleInfo.indicationInformations.subCategory.name" v-if="article.articleInfo.indicationInformations.subCategory != null"/>
      <LabelDescription title="Indikationen TCM" :content="article.articleInfo.indicationInformations.tcm" />
      <LabelDescription title="Indikationen Schulmedizin" :content="article.articleInfo.indicationInformations.schoolMedicine" />
      <LabelDescription title="Vorsichtsmassnahmen / Kontraindikationen" v-bind:content="article.articleInfo.indicationInformations.contraIndication" />
    </div>
    <div class="col-md-6">
      <LabelDescription title="Zungendiagnostik ZB" :content="article.articleInfo.indicationInformations.tongueDiagnosticsZB" />
      <LabelDescription title="Zungendiagnostik ZK" :content="article.articleInfo.indicationInformations.tongueDiagnosticsZK" />
<!--      <LabelDescription title="Empfohlene Tagesdosierung" :content="article.knowledgeComplemedis.dailyDosage" />-->
<!--      <LabelDescription title="Bemerkung zu Tagesdosierung" :content="article.knowledgeComplemedis.dailyDosageNote" />-->
      <LabelDescription title="Pulstastung" :content="article.articleInfo.indicationInformations.pulseSensing" />
      <LabelDescription title="Temperatur" :content="article.articleInfo.wDProperties.temperature" v-if="article.articleInfo.wDProperties != null" />
      <LabelDescription title="Geschmack" :content="article.articleInfo.wDProperties.smell" v-if="article.articleInfo.wDProperties != null"/>
      <LabelDescription title="Leitbahnen" :content="article.articleInfo.wDProperties.meridian" v-if="article.articleInfo.wDProperties != null"/>
      <v-divider></v-divider><br>
      <v-img v-bind:src="this.imgs" @click="showImg(0)" max-width="200"></v-img>
      <VueEasyLightbox
          :visible="visible"
          :imgs="imgs"
          :index="index"
          @hide="handleHide"
      ></VueEasyLightbox>
    </div>
  </div>
</template>

<script>
import LabelDescription from "@/components/LabelDescription";
import VueEasyLightbox from 'vue-easy-lightbox'

export default {
  name: "ArticleOverview",
  components: {LabelDescription, VueEasyLightbox},
  props: ['article'],
  data: function() {
    return {
      visible: false,
      index: 0,
      imgs: './../drugimages/' + this.article.articleNumber + '.jpg'
    }
  },
  methods: {
    showImg (index) {
      this.index = index
      this.visible = true
    },
    handleHide () {
      this.visible = false
    }
  }
}
</script>

<style scoped>

</style>
import { render, staticRenderFns } from "./LabelDescription.vue?vue&type=template&id=e8ec1d26&scoped=true&"
import script from "./LabelDescription.vue?vue&type=script&lang=js&"
export * from "./LabelDescription.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8ec1d26",
  null
  
)

export default component.exports
<template>
  <v-simple-table v-if="article != null">
    <template v-slot:default>
      <thead>
      <tr>
        <th class="text-left">
          Symptom
        </th>
        <th class="text-left">
          Artikel
        </th>
      </tr>
      </thead>
      <tbody>
      <tr
          v-for="item in article.modifications"
          :key="item.symptom"
      >
        <td>{{ item.symptom }}</td>
        <td>{{ item.addItems }}</td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: "ArticleModifications",
  props: ['article']
}
</script>

<style scoped>

</style>
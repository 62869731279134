import Vue from "vue";
import VueRouter from "vue-router";

import ErrorPage from "@/views/ErrorPage"
import TopBar from "@/components/TopBar";
import Login from "@/views/Login"
import AllArticles from "@/views/AllArticles";
import ArticleDetails from "@/views/ArticleDetails";
import EditArticle from "@/views/EditArticle";
import Jumpoff from "@/views/Jumpoff";
import store from '../store'

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        components: {
            default: AllArticles,
            left: null,
            appbar: TopBar
        },
        meta: { hideSidebar: true }
    },
    {
        path: "/detail/:articleNumber",
        name: "Detail",
        components: {
            default: ArticleDetails,
            left: null,
            appbar: TopBar
        },
        meta: { hideSidebar: true }
    },
    {
        path: "/edit/:articleNumber",
        name: "Edit",
        components: {
            default: EditArticle,
            left: null,
            appbar: TopBar
        },
        meta: { hideSidebar: true, requiresAuth: true }
    },
    {
        path: "/edit/",
        name: "Edit",
        components: {
            default: EditArticle,
            left: null,
            appbar: TopBar
        },
        meta: { hideSidebar: true, requiresAuth: true }
    },
    {
        path: "/jumpoff/:correlationId/:articleNumber",
        name: "Jumpoffarticle",
        components: {
            default: Jumpoff,
            left: null,
            appbar: TopBar
        },
        meta: { hideSidebar: true }
    },
    {
        path: "/jumpoff/:correlationId/",
        name: "Jumpoff",
        components: {
            default: Jumpoff,
            left: null,
            appbar: TopBar
        },
        meta: { hideSidebar: true }
    },
    {
        path: "/login",
        name: "Login",
        components: {
            default: Login,
            left: null,
            appbar: TopBar
        },
        meta: { hideSidebar: true }
    },
    {
        path: "/error",
        name: "Error",
        components: {
            root: ErrorPage,
            default: null,
            left: null,
            appbar: null
        },
        meta: { hideSidebar: true, isRoot: true }
    },

];

const router = new VueRouter({
    mode: "history",
    routes
});

router.beforeEach((to, from, next) => {
    // Check if the user is logged i
    const isUserLoggedIn = !!localStorage.getItem("apollo-token");
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!isUserLoggedIn) {
            store.dispatch('logOut')
            next({
                path: '/login',
                query: { redirect: to.fullPath }
            })
        } else {
            next()
        }
    } else {
        next()
    }
})

export default router;
<template>
  <v-app-bar
      app
      color="#EEEBD7"
      dark
      height="80px"
  >
    <div class="d-flex align-center" style="color: black">
      <v-img
          alt="Complemedis AG"
          src="@/appimages/complemedis-logo.svg"
          transition="scale-transition"
          width="115px"
          height="40px"
          @click.shift="goToLogin"
      /> <div style="padding-left: 15px;  font-size: larger; color: #474747">KOMPENDIUM</div>
    </div>

    <v-spacer></v-spacer>

    <v-btn
        href="https://www.compleweb.ch"
        target="_blank"
        text
    >
      <span class="mr-2 black--text">Compleweb</span>
      <v-icon class="black--text">mdi-open-in-new</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {
name: "TopBar",
  methods: {
    goToLogin(){
      this.$router.push('/login');
    }
  }
}
</script>

<style scoped>

</style>
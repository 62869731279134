<template>

  <div class="auth">
    <br><br>
    <v-card style="width: 800px;display: inline-block;text-align: center;">
    <h3>Log In</h3>
    <form action="POST" @submit.prevent="loginUser">
      <v-text-field style="width: 500px;display: inline-block;" type="text" name="email"  placeholder="Benutzername" v-model="authDetails.email" label="Benutzername" /><br>
      <v-text-field style="width: 500px;display: inline-block;" type="password" name="password" placeholder="Passwort" v-model="authDetails.password" /><br>
      <v-btn v-on:click="loginUser">Anmelden</v-btn><br>&nbsp;<br>&nbsp;
    </form>
    </v-card>
  </div>

</template>

<script>
import { mapActions } from 'vuex'
export default {
name: "Login",
  data () {
    return {
      authDetails: {
        email: '',
        password: ''
      }
    }
  },
  methods: {
    ...mapActions(['login']),
    loginUser: function () {
      this.login(this.authDetails)
          .then((value) => {
            this.$store.commit('SET_TOKEN', value);
            this.$router.push('/?admin=true');
          })
    }
  }
}
</script>

<style scoped>
  .auth {
    text-align: center;
  }
</style>
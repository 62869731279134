<template>
  <div class="labelDescription">
    <div class="smallTitle">{{ title }}</div>
    <div
        v-for="item in content"
        v-bind:key="item">
      {{item}}
    </div>
  </div>
</template>

<script>
export default {
  name: "LabelListDescription",
  props: [
      'title',
      'content'
  ]
}
</script>

<style scoped>

</style>
import { render, staticRenderFns } from "./EditArticleOverview.vue?vue&type=template&id=0cbf262b&scoped=true&"
import script from "./EditArticleOverview.vue?vue&type=script&lang=js&"
export * from "./EditArticleOverview.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cbf262b",
  null
  
)

export default component.exports
import { apolloClient } from '@/vue-apollo'
import QUERY_ARTICLES from "../graphql/GetArticles.gql"
import QUERY_ARTICLES_GENERAL from "../graphql/GetArticlesGeneral.gql"
import QUERY_BY_ARTICLE from "../graphql/GetArticle.gql"
import UPDATE_ARTICLE from "@/graphql/UpdateArticle.gql"
import NEW_ARTICLE from "@/graphql/NewArticle.gql"
import cloneDeep from 'clone-deep';
import omitDeep from 'omit-deep';
import Router from '../router/router'

export const getArticles = async(articleNumber,
                                 pharmaName,
                                 pinYin,
                                 indicationSchool,
                                 indicationTcm,
                                 tongue,
                                 pulse,
                                 selectedCategories,
                                 selectedTemperatures,
                                 selectedSmells,
                                 selectedMeridians) => {
    return await apolloClient.query( {
       query: QUERY_ARTICLES,
       variables: {articleNumber, pharmaName, pinYin, indicationSchool, indicationTcm, tongue, pulse, selectedCategories, selectedTemperatures, selectedSmells, selectedMeridians}
    });
}

export const getArticlesGeneral = async(general, selectedCategories, selectedTemperatures, selectedSmells, selectedMeridians) => {
    return await apolloClient.query( {
        query: QUERY_ARTICLES_GENERAL,
        variables: {general, selectedCategories, selectedTemperatures, selectedSmells, selectedMeridians}
    });
}

export const getArticlesByArticleNumber = async (articleNumber) => {
    return await apolloClient.query({
        query: QUERY_BY_ARTICLE,
        variables: { articleNumber }
    });
};

export const newArticle = async () => {
    return await apolloClient.query({
        query: NEW_ARTICLE
    });
};

export const updateArticle = async (article) => {
    var art = {
        "article": omitDeep(cloneDeep(article), '__typename')
    }
    return await apolloClient.mutate({
        mutation: UPDATE_ARTICLE,
        variables: { input: art },
    }).catch(error => {
        if(error.graphQLErrors[0].extensions.code == "AUTH_NOT_AUTHENTICATED") {
            Router.push('../login');
        }
    });
};
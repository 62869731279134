<template>
  <div>Sorry</div>
</template>

<script>
export default {
name: "ErrorPage"
}
</script>

<style scoped>

</style>
<template>
  <div class="labelDescription">
    <v-combobox
        v-model="mutableValue"
        :items="list"
        item-text="name"
        item-value="id"
        :label="title"
        v-on:change="changedSelection"
    ></v-combobox>

  </div>
</template>

<script>
export default {
  name: "LabelCombobox",
  props: [
    'title',
    'list',
    'value'
  ],
  data: function () {
    return {
      mutableValue: this.value
    }
  },
  methods: {
    changedSelection(a) {
      //this.$router.push({path: a })
      this.$emit('input', a);
    }
  },
  created() {
    console.log(this.list)

    console.log(this.value)
  }
}
</script>

<style scoped>

</style>
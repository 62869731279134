import {apolloClient} from "@/vue-apollo";
import QUERY_CATEGORIES from "@/graphql/GetCategories.gql";
import QUERY_SUBCATEGORIES from "@/graphql/GetSubCategories.gql";

export const getEffectCategories = async () => {
    return await apolloClient.query({
        query: QUERY_CATEGORIES
    });
};

export const getEffectSubCategoriesByCategoryId = async (id) => {
    return await apolloClient.query({
        query: QUERY_SUBCATEGORIES,
        variables: { id }
    });
};
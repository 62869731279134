<template>
  <div class="labelDescription">
    <v-text-field
        :label="title"
        hide-details
        v-model="content"
        @keyup="$emit('update:content', content)"
    ></v-text-field>

  </div>
</template>

<script>
export default {
  name: "LabelEdit",
  props: [
      'title',
      'content'
  ]
}
</script>

<style scoped>

</style>
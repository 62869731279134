import axios from "axios";

export const loginUser = async(commit, username, password) => {
    const params = new URLSearchParams()
    params.append('grant_type', 'password')
    params.append('username', username)
    params.append('password', password)
    params.append('scope', "offline_access compendium")

    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }

    const url = "https://auth.compleweb.ch/connect/token";
    await axios.post(url, params, config)
        .then((result) => {
            var token = result.data.access_token
            commit('SET_TOKEN', token)
            localStorage.setItem('apollo-token', token)
            return;
        })
        .catch((err) => {
            alert(err);
        })

}
<template>
  <div v-if="article != null" class="row">
    <div class="col-md-6">
      <LabelCombobox title="Kategorie" :list="categories" :value.sync="article.articleInfo.indicationInformations.category" v-on:input="changeCategory($event)" v-if="categories.length > 0"/>
      <LabelEdit title="Sub Kategorie" :content.sync="article.articleInfo.indicationInformations.subCategory" v-if="article.articleInfo.indicationInformations.subCategory != null"/>
      <LabelEdit title="Indikationen TCM" :content.sync="article.articleInfo.indicationInformations.tcm" />
      <LabelEdit title="Indikationen Schulmedizin" :content.sync="article.articleInfo.indicationInformations.schoolMedicine" />
      <LabelEdit title="Vorsichtsmassnahmen / Kontraindikationen" :content.sync="article.articleInfo.indicationInformations.contraIndication" />
    </div>
    <div class="col-md-6">
      <LabelEdit title="Zungendiagnostik ZB" :content.sync="article.articleInfo.indicationInformations.tongueDiagnosticsZB" />
      <LabelEdit title="Zungendiagnostik ZK" :content.sync="article.articleInfo.indicationInformations.tongueDiagnosticsZK" />
      <LabelEdit title="Empfohlene Tagesdosierung" :content.sync="article.knowledgeComplemedis.dailyDosage" />
      <LabelEdit title="Bemerkung zu Tagesdosierung" :content.sync="article.knowledgeComplemedis.dailyDosageNote" />
      <LabelEdit title="Pulstastung" :content.sync="article.articleInfo.indicationInformations.pulseSensing" />
    </div>
  </div>
</template>

<script>
import LabelEdit from "@/components/LabelEdit";
import {getEffectCategories} from "@/services/categoryService";
import LabelCombobox from "@/components/LabelCombobox";
export default {
  name: "EditArticleOverview",
  components: {LabelCombobox, LabelEdit},
  props: ['article'],
  data () {
    return {
      categories: [],
    }
  },
  methods: {
    async loadCategories() {
      var data = await getEffectCategories();
      this.categories = [];
      data.data.effectCategories.forEach(item => {

        this.categories.push(item)
      });
    },
    changeCategory(value) {
      this.article.articleInfo.indicationInformations.category = value
    }
  },
  created() {
    this.loadCategories();
  }
}
</script>

<style scoped>

</style>